export interface EntryTokens {
  application: string;
  ga: string;
  fbq: string
  ttq: string;
}

export const EntryConfigs = {
  "frecam": {
    // 2024
    application: "1080c0bb70e2169677b0a5d86ceaf931d734fe75720dfc09c065071b05e2f310",
    id: 18,
    // Analytics
    ga: "UA-52344329-13",
    fbq: "320057338981217",
    ttq: "C1MP5BGB69GLP6V5VFRG",
    entered_in: "2024"
  },
  "misscircle": {
    // 2024
    application: "b88bea71e7ef4da721656ceabc8117f55fffc0d02c0789d503d55dd532fff4c7",
    id: 19,
    // Analytics
    ga: "UA-52344329-14",
    fbq: "576234076334949",
    ttq: "C1MP86MDPLBQ4GPQ7E9G"
  }
}

export class Entry {
  data: { [key: string]: any };
  constructor(props: { [key: string]: any }) {
    this.data = props;
  }
  app(): EntryTokens {
    if(this.data.value['entered_in'] === EntryConfigs.frecam.entered_in) {
      return EntryConfigs.frecam;
    } else {
      return EntryConfigs.misscircle;
    }
  }
  toRequest(): { [key: string]: string } {
    return {
      ...this.data.value
    };
  }
}
