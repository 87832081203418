import { LineUser } from "../domains/LineUser";
import { delay } from "../utils/delay";
import {useSuspenseQuery} from "./useSuspenseQuery";

const mockResponse = {
  display_name: 'foobar',
  'line-user-id': 'U11eedee1202e5051762b3814c0f4cae8',
  'line-access-token':
      'eyJhbGciOiJIUzI1NiJ9.xfz8QMDBJetHzhk0Nnx8bLzi6fK_irEDC6DcIfuy3UFOwzKyXB5oqRaOSaPdZuFENPZptfncMR18z-yq7UAykEgETFJIFMjnnVQK1H_7_0OHXY2ADKGlG3kjZ2qJ8VfbtVzlIsE6Hjeo3PF4I06TUl1WR20eoCPQ26LPDtG33l0.HmJE-lMK2I9gsgRlwD09E1q6eLhhqCDx9Ak7JcrBRdc',
  friend_flag: true,
};

export class LineLoginError extends Error {}

export function useGetLineUser() {
  return useSuspenseQuery<LineUser>(["liff"], async () => {
    if (process.env.REACT_APP_DEBUG_MODE) {
      await delay(1000);
      return mockResponse;
    }
    return await liff
      .init({ liffId: process.env.REACT_APP_LIFF_ID! })
      .then(() => {
        if (!process.env.REACT_APP_DEBUG_MODE && !liff.isLoggedIn()) {
          liff.login({
            redirectUri: window.location.href
          });
        }
      })
      .then(() =>
        Promise.all([
          liff.getProfile(),
          liff.getFriendship().catch(e => ({ friendFlag: false }))
        ])
      )
      .then(([profile, friendship]) => {
        return {
          display_name: profile.displayName,
          "line-access-token": liff.getAccessToken(),
          "line-user-id": profile.userId,
          friend_flag: friendship.friendFlag
        };
      })
      .catch((error) => {
        console.log("DEBUG", error);
        throw new LineLoginError();
      });
  }, {retry: false});
}
