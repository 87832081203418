import ReactGA from "react-ga";
import ReactPixel from "react-facebook-pixel";
import ReactTicTokPixel from "../utils/tictok";
import url from "url";
import { useAmplitude } from "react-amplitude-hooks";
import {EntryConfigs} from "../domains/Entry";

export function useAnalytics() {
  const { logEvent } = useAmplitude();
  const {
    query: { ga, fbq, ttq, application, ...queries }
  } = url.parse(window.location.href, true);
  return {
    initialize: () => {
      if (process.env.REACT_APP_DEBUG_MODE) {
        console.log("[DEBUG] useAnalytics.initialize");
        return;
      }
      if (ga) {
        ReactGA.initialize([
          {
            trackingId: EntryConfigs.frecam.ga,
            gaOptions: { name: EntryConfigs.frecam.application }
          },
          {
            trackingId: EntryConfigs.misscircle.ga,
            gaOptions: { name: EntryConfigs.misscircle.application }
          }
        ]);
      }
      if (fbq) {
        ReactPixel.init(fbq as string, undefined, {
          autoConfig: true,
          debug: false
        });
      }
      if(ttq) {
        ReactTicTokPixel.init(ttq as string);
      }
    },
    pageview: () => {
      const url = `${window.location.origin}${window.location.pathname}`;
      if (process.env.REACT_APP_DEBUG_MODE) {
        console.log("[DEBUG] useAnalytics.pageview", url);
        return;
      }
      ReactGA.pageview(window.location.pathname, [`${application}`]);
      ReactPixel.pageView();
      logEvent(`view ${url}`, {
        url: window.location.href,
        ...queries
      });
    },
    cv: () => {
      const url = `${window.location.origin}${window.location.pathname}`;
      if (process.env.REACT_APP_DEBUG_MODE) {
        console.log("[DEBUG] useAnalytics.cv", url);
        return;
      }
      ReactGA.event({
        category: "Liff",
        action: "Entry Succeeded"
      }, [`${application}`]);
      ReactPixel.track("Entry", {
        value: 5000,
        currency: "JPY"
      });
      ReactTicTokPixel.track("SubmitForm");
      logEvent(`entry succeeded`, {
        url: window.location.href,
        ...queries
      });
    }
  };
}
