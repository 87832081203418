import React from "react";
import ReactDOM from "react-dom";
import classNames from "classnames";

export function Spinner({ active }: { active?: boolean }) {
  return ReactDOM.createPortal(
    <div
      className={classNames("Loading", {
        "is-active": active
      })}
    >
      <div>
        <div />
        <div />
      </div>
    </div>,
    document.getElementById("loaders")!
  );
}
